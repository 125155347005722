import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Order } from './Order'

export class CargoLine extends Model {
    static backendResourceName = 'cargo_line';

    @observable id = null;
    @observable booking = null;
    @observable packagingAmount = null;
    @observable packagingType = '';
    @observable weight = null;
    @observable adr = false;
    @observable cargoDescription = '';
    @observable mrn = null;
    @observable ucr = null;
    @observable transportDocumentNr = null;
    @observable documentType = '';
    @observable expiryDate = null;
    @observable customsOfficeCode = '';

    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            order: Order,
        };
    }

    casts() {
        return {
            expiryDate: Casts.luxonDate,
        };
    }
}

export class CargoLineStore extends Store {
    Model = CargoLine;
    static backendResourceName = 'cargo_line';
}
